import { PageInfo } from './general';
import { IAccountIntegrationMetadataType } from './IAccountIntegration';

export const EXAMPLE_QUESTION_CATEGORIES = {
  sales: 'Sales',
  paidMarketing: 'Paid Marketing',
  revenue: 'Revenue',
  webTraffic: 'Web Traffic'
};

export class INlqInternalQuestion {
  question: string;
  integration: IAccountIntegrationMetadataType;
}

export class ExecuteExampleQuestionDto {
  slug!: string;
  displayQuestion!: string;
  internalQuestions!: INlqInternalQuestion[];
  additionalInstructions?: string;
}

export class ISetNlqExampleQuestion {
  parentId?: string;
  displayQuestion!: string;
  internalQuestions?: INlqInternalQuestion[];
  relatedQuestions?: string[];
  additionalInstructions?: string;
  associatedSlug?: string;
  associatedCategories?: string[];
  variantExampleQuestionIds?: string[];
  internalNotes?: string;
  workflowDescription?: string;
  childIds?: string[];
}

export class IPatchNlqExampleQuestion {
  parentId?: string;
  displayQuestion?: string;
  internalQuestions?: INlqInternalQuestion[];
  relatedQuestions?: string[];
  additionalInstructions?: string;
  associatedSlug?: string;
  associatedCategories?: string[];
  variantExampleQuestionIds?: string[];
  internalNotes?: string;
  workflowDescription?: string;
  childIds?: string[];
}

export class NlqExampleQuestion {
  id?: string;
  displayQuestion?: string;
  internalQuestions?: INlqInternalQuestion[];
  relatedQuestions?: string[];
  additionalInstructions?: string;
  modifiedAt?: string;
  createdAt?: string;
  createdBy?: string;
  lastModifiedBy?: string;
  categories?: string[];
  slug?: string;
  variantExampleQuestions?: NlqExampleQuestion[];
  internalNotes?: string;
  workflowDescription?: string;
  parentId?: string;
  children?: NlqExampleQuestion[];
}

export class INlqExampleQuestionCategoriesListing {
  [key: string]: NlqExampleQuestion[];
}

export class INlqExampleQuestionCategoriesListingResults {
  categories!: INlqExampleQuestionCategoriesListing;
}

export class INlqExampleQuestionListingEdge {
  cursor?: string;
  offset?: number;
  node!: NlqExampleQuestion;
}

export class INlqExampleQuestionListingResults {
  pageInfo!: PageInfo;
  edges!: INlqExampleQuestionListingEdge[];
}

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  FormRecord,
  FormControl,
  AbstractControl
} from '@angular/forms';

import { plainToClass } from 'class-transformer';
import { filter, map } from 'rxjs';

import {
  DEFAULT_BLOCK_PADDING,
  NlqSummarySingleSendBlock,
  NlqSummarySingleSendBlockPrompt,
  PromptControlType
} from 'models';
import { BaseComponent } from 'uikit';

@Component({
  selector: 'edit-nlq-summary-block',
  templateUrl: './edit-nlq-summary-block.component.html',
  styleUrls: ['./edit-nlq-summary-block.component.less']
})
export class EditNlqSummaryBlockComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() block: NlqSummarySingleSendBlock;

  @Output() onUpdatedBlock: EventEmitter<NlqSummarySingleSendBlock> =
    new EventEmitter<NlqSummarySingleSendBlock>();

  formGroup: UntypedFormGroup;
  promptForm: FormRecord<FormControl<NlqSummarySingleSendBlockPrompt>> =
    this._formBuilder.record({});
  promptsControls: Array<PromptControlType> = [];

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _cdr: ChangeDetectorRef
  ) {
    super();
  }

  getTitleForControl(control: PromptControlType) {
    return this.promptForm.get(control?.controlInstance)?.value?.prompt ?? '';
  }

  ngOnInit(): void {
    this._initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    this._initForm();
  }

  private _initForm() {
    this.promptForm = this._formBuilder.record({});
    this.promptsControls = [];

    this.block?.prompts?.forEach((prompt) =>
      this._addField(
        this._formBuilder.group({
          prompt: [prompt?.prompt, Validators.required],
          additionalInstructions: [prompt?.additionalInstructions]
        })
      )
    );

    this.formGroup = this._formBuilder.group({
      summarizationInstructions: [this.block?.summarizationInstructions ?? ''],
      paddingTop: [
        this.block?.paddingTop ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ],
      paddingRight: [
        this.block?.paddingRight ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ],
      paddingBottom: [
        this.block?.paddingBottom ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ],
      paddingLeft: [
        this.block?.paddingLeft ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ],
      prompts: this.promptForm
    });

    this.formGroup.valueChanges
      .pipe(
        filter(() => this.formGroup.valid),
        map((value) =>
          plainToClass(NlqSummarySingleSendBlock, {
            ...this.block,
            ...value,
            prompts: Object.values(this.promptForm.value)
          })
        ),
        this.takeUntilChanges
      )
      .subscribe((block) => {
        this.onUpdatedBlock.emit(block);
        this._cdr.detectChanges();
      });
  }

  handleAddField(e?: MouseEvent): void {
    e?.preventDefault();

    this._addField(
      this._formBuilder.group({
        prompt: ['', Validators.required],
        additionalInstructions: ['']
      })
    );
  }

  handleRemoveField(i: PromptControlType, e?: MouseEvent): void {
    e?.preventDefault();
    const index = this.promptsControls.indexOf(i);
    this.promptsControls.splice(index, 1);
    this.promptForm.removeControl(i.controlInstance);
  }

  private _addField(control: AbstractControl) {
    const id =
      this.promptsControls.length > 0
        ? this.promptsControls[this.promptsControls.length - 1].id + 1
        : 0;
    const controlStruct = {
      id,
      controlInstance: `prompt${id}`
    } as PromptControlType;
    const index = this.promptsControls.push(controlStruct);
    this.promptForm.addControl(
      this.promptsControls[index - 1].controlInstance,
      control as any,
      { emitEvent: false }
    );
  }
}
